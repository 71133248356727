export const openSidebar = () => {
	// open sidebar
	window.$('#sidebar').addClass('active');
	// fade in the overlay
	window.$('.overlay').addClass('active');
	window.$('.collapse.in').toggleClass('in');
	window.$('a[aria-expanded=true]').attr('aria-expanded', 'false');
};

export const closeSidebar = () => {
	// hide sidebar
	window.$('#sidebar').removeClass('active');
	// hide overlay
	window.$('.overlay').removeClass('active');
};

export const showSite = () => {
	document.getElementById('loader-container').style.display = 'none';
	document.getElementById('app').style.display = 'block';
};
